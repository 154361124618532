import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';

import styles from './Advantages.module.scss';

const Advantages: React.FC<any> = () => {
  const { t } = useTranslation('homepage');

  const images = [
    {
      image: 'advantages-img-1',
      additionalClass: styles.advantages__item1,
      hasMobile: true,
    },
    {
      image: 'advantages-img-2',
      additionalClass: styles.advantages__item2,
    },
    {
      image: 'advantages-img-3',
      additionalClass: styles.advantages__item3,
    },
  ];

  interface Images {
    image: string;
    additionalClass: string;
    hasMobile?: boolean;
  }

  return (
    <section className={styles.advantages} id={'advantages'}>
      <div className={'container'}>
        {images.map((value: Images, index: number) => (
          <div
            key={index}
            className={cn(styles.advantages__item, value.additionalClass, {
              [styles.advantages__itemReverse]: index % 2 != 0,
            })}
          >
            <div className={styles.advantages__info}>
              <h2 className={cn('title', 'title__main')}>
                {t(`advantages.${index}.title`)}
              </h2>
              <div className={styles.advantages__text}>
                {t(`advantages.${index}.text`)}
              </div>
            </div>
            <div className={styles.advantages__image}>
              {value.hasMobile ? (
                <picture>
                  <source
                    srcSet={`/images/${value.image}-mobile.png`}
                    media={'(max-width: 767px)'}
                  />
                  <img
                    src={`/images/${value.image}.png`}
                    srcSet={`/images/${value.image}.png, /images/${value.image}-2x.png 2x`}
                    className={'img'}
                  />
                </picture>
              ) : (
                <img
                  src={`/images/${value.image}.png`}
                  srcSet={`/images/${value.image}.png, /images/${value.image}-2x.png 2x`}
                  className={'img'}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Advantages;
