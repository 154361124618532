import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'next-i18next';

import styles from './Main.module.scss';
import buttonStyles from 'components/blocks/Button/Button.module.scss';
import cn from 'classnames';
import ScrollIntoView from 'react-scroll-into-view';
import Button from 'components/blocks/Button';

const Main: React.FC<any> = () => {
  const { t } = useTranslation('homepage');

  const [isMobile, setIsMobile] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [showPlayButton, toggleShowPlayButton] = useState(false);

  const videoRef = useRef(null);

  const checkMobile = useCallback(() => {
    const isMob = window.innerWidth < 768;
    setIsMobile(isMob);
    return isMob;
  }, []);

  const resize = () => {
    if (window.innerWidth !== windowWidth) {
      setWindowWidth(window.innerWidth);
      const mob = checkMobile();

      if (!mob) {
        playVideo();
      }
    }
  };

  const playVideo = function () {
    const video = videoRef.current;

    if (!video) {
      return;
    }

    video.play();

    // fix for old apple devices
    if (video.paused) {
      toggleShowPlayButton(true);
      return;
    }

    toggleShowPlayButton(false);
  };

  useEffect(() => {
    if (process.browser) {
      resize();
      setWindowWidth(window.innerWidth);
      window.addEventListener('resize', resize);
    }
    return () => {
      if (process.browser) {
        window.removeEventListener('resize', resize);
      }
    };
  });

  return (
    <main className={styles.main}>
      <div className='container'>
        <div className={styles.main__wrapper}>
          {isMobile ? (
            <div className={styles.main__image}>
              <img
                className={cn(styles.main__img, 'img')}
                src={'/images/main-image.jpg'}
                srcSet={'/images/main-image.jpg, /images/main-image-2x.jpg 2x'}
              />
              <div className={styles.main__phone}>
                <img
                  className={cn(styles.main__phoneImg, 'img')}
                  src={'/images/main-phone.png'}
                  srcSet={
                    '/images/main-phone.png, /images/main-phone-2x.png 2x'
                  }
                />
              </div>
            </div>
          ) : (
            <div className={styles.main__videoFull}>
              <video
                preload={'auto'}
                muted={true}
                playsInline={true}
                loop={true}
                poster={'/images/main-screen-video-poster.jpg'}
                ref={videoRef}
              >
                <source src={'/video/main-screen-video.mp4#t=0.001'} />
                <source src={'/video/main-screen-video.webm#t=0.001'} />
                <source src={'/video/main-screen-video.m4v#t=0.001'} />
              </video>
              {showPlayButton && (
                <div
                  className={styles.main__videoPlayButton}
                  onClick={() => playVideo()}
                />
              )}
            </div>
          )}
          <div className={styles.main__info}>
            <div className={styles.main__titles}>
              <h1 className={cn('title', 'title__main')}>{t('main.title')}</h1>
              <div className={styles.main__subTitle}>{t('main.subTitle')}</div>
            </div>
            <div className={styles.main__button}>
              <ScrollIntoView selector={'#feedback'}>
                <Button
                  placeholder={t('main.button')}
                  addStyles={[buttonStyles.button__w100]}
                />
              </ScrollIntoView>
            </div>
          </div>
          <div className={styles.main__arrow}>
            <svg
              width='38'
              height='60'
              viewBox='0 0 38 60'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M21.502 0.900391L21.502 53.6548L16.2519 53.6548L16.252 0.90039L21.502 0.900391Z'
                fill='#34C759'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18.6904 52.5225L33.8771 37.2621L37.252 40.6533L18.6904 59.3048L0.128857 40.6533L3.50368 37.2621L18.6904 52.5225Z'
                fill='#34C759'
              />
            </svg>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Main;
