import React from 'react';
import Layout from 'components/layouts/Layout/Layout';
import { FaqItem, ReviewsItem } from 'services/homepageService';
import { ProductItem } from 'interfaces/Product/ProductItemInterface';
import Main from 'components/blocks/HomePage/Main';
import Advantages from 'components/blocks/HomePage/Advantages';
import FeedbackMain from 'components/blocks/FeedbackMain';
import ReviewsImages from 'components/blocks/HomePage/ReviewsImages';

interface HomePageProps {
  faqData: FaqItem[];
  reviewsData: ReviewsItem[];
  productData: ProductItem[];
}

const HomePage: React.FC<HomePageProps> = () => {
  return (
    <Layout>
      <div className={'lock'}>
        <Main />
        <Advantages />
        <FeedbackMain />
        {/*<ReviewsImages />*/}
      </div>
    </Layout>
  );
};

export default HomePage;
