import { AxiosError, AxiosResponse } from 'axios';
import { httpClient } from 'helpers/httpClient';
import { FeedbackData } from 'interfaces/Feedback/Feedback';

type FeedbackDataResponse = FeedbackData;

export const sendFeedback = async (
  data
): Promise<AxiosResponse<FeedbackDataResponse>> => {
  try {
    const tokens = await httpClient.post<any>('/api/tg');

    if (tokens.status !== 200) {
      return tokens;
    }

    let message = '<b>Новая заявка</b>\n';

    for (let key in data) {
      let keyName;

      switch (key) {
        case 'name':
          keyName = 'Имя';
          break;
        case 'email':
          keyName = 'Email';
          break;
        case 'phone':
          keyName = 'Телефон';
          break;
      }

      if (keyName) {
        message += `<b>${keyName}:</b> ${data[key]}\n`;
      }
    }

    const response = await httpClient.post<FeedbackDataResponse>(
      `https://api.telegram.org/bot${tokens.data.token}/sendMessage`,
      { chat_id: tokens.data.chat_id, parse_mode: 'html', text: message }
    );
    return response;
  } catch (e) {
    console.error(e.response.data);
    return e.response.data.description as AxiosError<unknown> as never;
  }
};
