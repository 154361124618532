import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';

import styles from './FeedbackMain.module.scss';
import buttonStyles from './../Button/Button.module.scss';
import Modal from '../Modal';
import { ModalData } from 'interfaces/Modal/Modal';
import { sendFeedback } from 'services/sendTelegramService';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { FeedbackData } from 'interfaces/Feedback/Feedback';
import Button from '../Button';

const FeedbackMain: React.FC<any> = () => {
  const { t } = useTranslation('feedback');

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [privacyCheckboxError, setPrivacyCheckboxError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState<ModalData>({
    title: '',
    buttonText: '',
  });

  const handleSuccessResult = () => {
    setShowModal(true);
    setModalData({
      title: t('feedback.modal.successModal.title'),
      buttonText: t('feedback.modal.successModal.buttonText'),
    });
  };

  const handleErrorResult = errorMessage => {
    setShowModal(true);
    setModalData({
      title: `${t('feedback.modal.errorModal.title')} ${
        errorMessage ? errorMessage : ''
      }`,
      buttonText: t('feedback.modal.errorModal.buttonText'),
    });
  };

  const handleValidate = useCallback(values => {
    interface error {
      name?: string;
      email?: string;
      data_processing?: string;
      phone?: string;
    }

    setEmailError(false);
    setNameError(false);
    setPrivacyCheckboxError(false);
    setPhoneError(false);

    const errors: error = {};

    if (!values.email.trim()) {
      errors.email = t('feedback.emailField.emailEmptyText');

      setEmailError(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = t('feedback.emailField.emailErrorText');

      setEmailError(true);
    }

    if (!values.name.trim()) {
      errors.name = t('feedback.nameField.nameEmptyText');

      setNameError(true);
    } else if (values.name.length < 2 || values.name.length > 100) {
      errors.name = t('feedback.nameField.nameErrorText');

      setNameError(true);
    }

    if (!values.phone.trim()) {
      errors.phone = t('feedback.phoneField.phoneEmptyText');

      setPhoneError(true);
    } else if (!/^([+]?[0-9\s\-\(\)]{5,25})*$/i.test(values.phone)) {
      errors.phone = t('feedback.phoneField.phoneErrorText');

      setPhoneError(true);
    }

    if (!values.data_processing) {
      setPrivacyCheckboxError(true);

      errors.data_processing = t('feedback.privacyCheckbox.privacyErrorText');
    }

    return errors;
  }, []);

  const handleSubmit = useCallback(async (data: FeedbackData, actions) => {
    const { setSubmitting, resetForm } = actions;
    const response = await sendFeedback(data);
    setSubmitting(false);

    if (response.status === 200) {
      handleSuccessResult();
      resetForm({});
    } else if (response.status === 422) {
      handleErrorResult(response.data.errors);
    } else {
      handleErrorResult(response.data);
    }
  }, []);

  return (
    <section id='feedback' className={styles.feedback}>
      <div className={styles.feedback__wrapper}>
        <div className={cn('container', styles.feedback__container)}>
          <div className={styles.feedback__demo}>{t('feedback.demo')}</div>
          <Formik
            initialValues={{
              name: '',
              email: '',
              phone: '',
              data_processing: false,
            }}
            validate={handleValidate}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className={styles.feedback__list}>
                <div className={styles.feedback__info}>
                  <h2 className={cn('title', 'title__main')}>
                    {t('feedback.title')}
                  </h2>
                  <div className={styles.feedback__subtitle}>
                    {t('feedback.subtitle')}
                  </div>
                </div>
                <div className={styles.feedback__form}>
                  <div className={styles.feedback__field}>
                    <Field
                      type='name'
                      name='name'
                      placeholder={t('feedback.nameField.nameInputText')}
                      className={cn(styles.feedback__fieldInput, {
                        [styles.error]: nameError,
                      })}
                    />
                    {nameError && (
                      <ErrorMessage
                        name='name'
                        component='p'
                        className={styles.feedback__errorLabel}
                      />
                    )}
                  </div>
                  <div className={styles.feedback__field}>
                    <Field
                      type='tel'
                      name='phone'
                      placeholder={t('feedback.phoneField.phoneInputText')}
                      className={cn(styles.feedback__fieldInput, {
                        [styles.error]: phoneError,
                      })}
                    />
                    {phoneError && (
                      <ErrorMessage
                        name='phone'
                        component='p'
                        className={styles.feedback__errorLabel}
                      />
                    )}
                  </div>
                  <div className={styles.feedback__field}>
                    <Field
                      type='email'
                      name='email'
                      placeholder={t('feedback.emailField.emailInputText')}
                      className={cn(styles.feedback__fieldInput, {
                        [styles.error]: emailError,
                      })}
                    />
                    {emailError && (
                      <ErrorMessage
                        name='email'
                        component='p'
                        className={styles.feedback__errorLabel}
                      />
                    )}
                  </div>
                  <div className={styles.feedback__field}>
                    <Field
                      id={'privacyCheckboxFeedback'}
                      type='checkbox'
                      name='data_processing'
                      className={styles.feedback__privacyCheckbox}
                    />
                    <label
                      htmlFor='privacyCheckboxFeedback'
                      className={cn(styles.feedback__privacyLabel, {
                        [styles.error]: privacyCheckboxError,
                      })}
                    >
                      {t('feedback.privacyCheckbox.privacyLabel')}
                    </label>
                    {privacyCheckboxError && (
                      <ErrorMessage
                        name='data_processing'
                        component='p'
                        className={styles.feedback__errorLabel}
                      />
                    )}
                  </div>
                  <div className={styles.feedback__submit}>
                    <Button
                      placeholder={t('feedback.buttonSubmitText')}
                      disabled={isSubmitting}
                      type='submit'
                      addStyles={[buttonStyles.button__w100]}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      {showModal && (
        <Modal
          showModal={setShowModal}
          title={modalData.title}
          buttonText={modalData.buttonText}
        />
      )}
    </section>
  );
};

export default FeedbackMain;
