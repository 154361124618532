import HomePage from 'components/pages/HomePage';
import { getFaq, getProducts, getReviews } from 'services/homepageService';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { setCookie } from 'cookies-next';

export default HomePage;

export const getServerSideProps = async props => {
  /*const faqResponse = await getFaq();
  const reviewsResponse = await getReviews();
  const productResponse = await getProducts();

  if (
    faqResponse.status === 500 ||
    reviewsResponse.status === 500 ||
    productResponse.status === 500
  ) {
    return {
      redirect: {
        destination: '/500',
        permanent: false,
      },
    };
  }*/

  const req = props.req,
    res = props.res;

  if (
    !props.req.cookies.next_locale ||
    props.req.cookies.next_locale !== props.locale
  ) {
    setCookie('next_locale', props.locale, {
      req,
      res,
      maxAge: 60 * 60 * 24 * 365,
    });
  }

  return {
    props: {
      ...(await serverSideTranslations(props.locale, [
        'common',
        'homepage',
        'header',
        'footer',
        'feedback',
      ])),
    },
  };
};
